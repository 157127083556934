:root {
  --primary-color: #BCA36E;
  --primary-600: #BCA36E;
  --brand-color: #BCA36E !important;
  --primary-bg: #F7F8F9 !important;
  --secondary-bg: #F8F9FA !important;
  --tertiary-bg: #F5F5F5 !important;
  --bg4: #64707C !important;
  --bg5: #F6F6F6 !important;
  --bg6: #E2E7EC !important;
  --bg7: #FAFAFA !important;
  --tab-bg: #485473 !important;
  --input-bg: #F8F9F9 !important;
  --black: #000 !important;
  --black1: #303C44 !important;
  --black2: #2E2E2E !important;
  --black3: #263238 !important;
  --black4: #212529 !important;
  --black5: #172733 !important;
  --black6: #1E212C !important;
  --primary-grey: #445261 !important;
  --grey1: #303C44 !important;
  --grey2: #59656D !important;
  --grey3: #555151 !important;
  --gold-bg: #DBCEB2 !important;
  --gold-light-bg: #F2EDE0 !important;
  --yellow1: #FDCE6B !important;
  --yellow2: #BCA36E !important;
  --border: #E3E5E7 !important;
  --border2: #ECEEEF !important;
  --green-1: #6FCF97 !important;
  --yellow-1 : #FFD54F !important;
  --black-1: #03224C !important;
  --blue-1: #2F80ED !important;
  --grey: #54565b !important;
  --background: #F6F6F6 !important;

  --background-semantic-success: #84bd00;
  --background-semantic-warning: #de6421;
  --background-accent-light: #c49a6c;
  --background-accent-light-opacity: #ecc17e4d;
  --background-accent-medium: #c49a6c;
  --background-accent-strong: #c49a6c;
  --background-neutral-0: #fafdff;
  --background-neutral-10: #eef2f5;
  --background-neutral-100: #58585a;
  --background-neutral-15: #dde4e9;
  --background-neutral-20: #e7e7e8;
  --background-neutral-30: #c7c8ca;
  --background-neutral-40: #939598;
  --background-neutral-50: #858f96;
  --background-neutral-60: #6f7a81;
  --background-neutral-70: #59656d;
  --background-neutral-80: #455057;
  --background-neutral-90: #303c44;
  --background-neutral-white: #ffffff;
  --background-secondary-light: #fac982;
  --background-secondary-medium: #c47f00;
  --background-secondary-strong: #a36a00;
  --text-semantic-success: #84bd00;
  --text-semantic-warning: #FFAD00;
  --text-accent-light: #c49a6c;
  --text-accent-light-opacity: #ecc17e4d;
  --text-accent-medium: #c49a6c;
  --text-accent-strong: #c49a6c;
  --text-neutral-0: #fafdff;
  --text-neutral-10: #eef2f5;
  --text-neutral-100: #58585a;
  --text-neutral-15: #dde4e9;
  --text-neutral-20: #e7e7e8;
  --text-neutral-30: #c7c8ca;
  --text-neutral-40: #939598;
  --text-neutral-50: #858f96;
  --text-neutral-60: #6f7a81;
  --text-neutral-80: #455057;
  --text-neutral-90: #303c44;
  --text-neutral-body: #59656d;
  --text-neutral-title: #172733;
  --text-neutral-white: #ffffff;
  --text-secondary-light: #fac982;
  --text-secondary-medium: #c47f00;
  --text-secondary-strong: #a36a00;
  // --brick-text-color: #7ebd7d;
  // --brick-background-color: #ecf4ec;

  /* Points Xchange Specific */
  --maskbg: rgba(0, 0, 0, 0.32);
  --feedback-background-positive-low-contrast: hsla(155, 100%, 31%, 0.18);
  --feedback-background-negative-low-contrast: hsla(9, 91%, 56%, 0.18);
  --feedback-background-notice-low-contrast: hsla(36, 100%, 44%, 0.09);
  --feedback-background-information-low-contrast: hsla(193, 100%, 35%, 0.09);
  --feedback-background-neutral-low-contrast: hsla(216, 15%, 54%, 0.09);

  --feedback-background-positive-high-contrast: hsla(160, 100%, 26%, 1);
  --feedback-background-negative-high-contrast: hsla(8, 73%, 47%, 1);
  --feedback-background-notice-high-contrast: hsla(38, 97%, 38%, 1);
  --feedback-background-information-high-contrast: hsla(193, 100%, 35%, 1);
  --feedback-background-neutral-high-contrast: hsla(216, 33%, 29%, 1);

  --feedback-border-positive-low-contrast: hsla(155, 100%, 31%, 0.32);
  --feedback-border-negative-low-contrast: hsla(9, 91%, 56%, 0.32);
  --feedback-border-notice-low-contrast: hsla(36, 100%, 44%, 0.32);
  --feedback-border-information-low-contrast: hsla(193, 100%, 35%, 0.32);
  --feedback-border-neutral-low-contrast: hsla(216, 15%, 54%, 0.32);

  --feedback-border-positive-high-contrast: hsla(160, 100%, 26%, 1);
  --feedback-border-negative-high-contrast: hsla(8, 73%, 47%, 1);
  --feedback-border-notice-high-contrast: hsla(38, 97%, 38%, 1);
  --feedback-border-information-high-contrast: hsla(193, 100%, 35%, 1);
  --feedback-border-neutral-high-contrast: hsla(216, 33%, 29%, 1);

  --feedback-text-positive: hsla(160, 100%, 26%, 1);
  --feedback-text-negative: hsla(8, 73%, 47%, 1);
  --feedback-text-notice: hsla(38, 97%, 38%, 1);
  --feedback-text-information: hsla(193, 100%, 35%, 1);
  --feedback-text-neutral: hsla(216, 27%, 36%, 1);

  --surface-border: var(--background-neutral-40);
  --surface-text-muted-low-contrast: var(--text-neutral-white);

  --brick-background-color: rgb(236, 244, 236);
  --brick-color: rgb(125, 189, 125);
}
.black1{
  color: var(--black1);
}
.black2{
  color: var(--black2);
}
.grey1{
  color: var(--grey1);
}
.brand-btn{
  background-color: var(--brand-color);
  color: #fff !important;
}
.form-control{
  background: var(--input-bg) !important;
}

/* Background Colors */
.background-semantic-success {
  background-color: var(--background-semantic-success) !important;
}

.background-semantic-warning {
  background-color: var(--background-semantic-warning) !important;
}

.background-accent-light {
  background-color: var(--background-accent-light) !important;
}

.background-accent-light-opacity {
  background-color: var(--background-accent-light-opacity) !important;
}

.background-accent-medium {
  background-color: var(--background-accent-medium) !important;
}

.background-accent-strong {
  background-color: var(--background-accent-strong) !important;
}

.background-neutral-10 {
  background-color: var(--background-neutral-10) !important;
}

.background-neutral-100 {
  background-color: var(--background-neutral-100) !important;
}

.background-neutral-15 {
  background-color: var(--background-neutral-15) !important;
}

.background-neutral-20 {
  background-color: var(--background-neutral-20) !important;
}

.background-neutral-30 {
  background-color: var(--background-neutral-30) !important;
}

.background-neutral-40 {
  background-color: var(--background-neutral-40) !important;
}

.background-neutral-60 {
  background-color: var(--background-neutral-60) !important;
}

.background-neutral-70 {
  background-color: var(--background-neutral-70) !important;
}

.background-neutral-80 {
  background-color: var(--background-neutral-80) !important;
}

.background-neutral-90 {
  background-color: var(--background-neutral-90) !important;
}

.background-neutral-bg {
  background-color: var(--background-neutral-bg) !important;
}

.background-neutral-other {
  background-color: var(--background-neutral-other) !important;
}

.background-neutral-white {
  background-color: var(--background-neutral-white) !important;
}

.background-secondary-light {
  background-color: var(--background-secondary-light) !important;
}

.background-secondary-medium {
  background-color: var(--background-secondary-medium) !important;
}

.background-secondary-strong {
  background-color: var(--background-secondary-strong) !important;
}

/* Text Colors */
.text-semantic-success {
  color: var(--text-semantic-success) !important;
}

.text-semantic-warning {
  color: var(--text-semantic-warning) !important;
}

.text-accent-light {
  color: var(--text-accent-light) !important;
}

.text-accent-light-opacity {
  color: var(--text-accent-light-opacity) !important;
}

.text-accent-medium {
  color: var(--text-accent-medium) !important;
}

.text-accent-strong {
  color: var(--text-accent-strong) !important;
}

.text-neutral-10 {
  color: var(--text-neutral-10) !important;
}

.text-neutral-100 {
  color: var(--text-neutral-100) !important;
}

.text-neutral-15 {
  color: var(--text-neutral-15) !important;
}

.text-neutral-20 {
  color: var(--text-neutral-20) !important;
}

.text-neutral-30 {
  color: var(--text-neutral-30) !important;
}

.text-neutral-40 {
  color: var(--text-neutral-40) !important;
}

.text-neutral-60 {
  color: var(--text-neutral-60) !important;
}

.text-neutral-80 {
  color: var(--text-neutral-80) !important;
}

.text-neutral-90 {
  color: var(--text-neutral-90) !important;
}

.text-neutral-bg {
  color: var(--text-neutral-bg) !important;
}

.text-neutral-body {
  color: var(--text-neutral-body) !important;
}

.text-neutral-other {
  color: var(--text-neutral-other) !important;
}

.text-neutral-title {
  color: var(--text-neutral-title) !important;
}

.text-neutral-white {
  color: var(--text-neutral-white) !important;
}

.text-secondary-light {
  color: var(--text-secondary-light) !important;
}

.text-secondary-medium {
  color: var(--text-secondary-medium) !important;
}

.text-secondary-strong {
  color: var(--text-secondary-strong) !important;
}

.fill-text-accent-medium {
  fill: var(--text-accent-medium) !important;
}

.border-neutral-20 {
  border-color: var(--background-neutral-20);
}

/* You can add global styles to this file, and also import other style files */

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// @import "./fontastic-font.css";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import "./theme/three-dots.css";
@import "./theme/variables.scss";
@import "./theme/base-css.scss";
@import "./theme/overides.scss";

@import 'primeng/resources/primeng.css';
@import '@pv-frontend/pv-shared-components/resources/styles/theme.css';
@import '@pv-frontend/pv-shared-components/resources/styles/primeflex.css';
@import '@pv-frontend/pv-shared-components/resources/styles/custom-theme.scss';

body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  margin: 0;
}
.authenticationModal {
  width: 420px !important;
  max-width: 420px !important;
  @media (max-width: 768px) {
    max-width: 90vw !important;
  }
  @media (max-width: 386px) {
    max-width: unset !important;
  }
  .mat-dialog-container {
    overflow: visible !important;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track{
  background-color: #fefefe;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--brand-color); 
}

app-home-top-part .category-bar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

// app-home-top-part .category-bar::-webkit-scrollbar-track {
//   background-color: #fefefe;
//   -webkit-border-radius: 1px;
//   border-radius: 1px;
// }

// app-home-top-part .category-bar::-webkit-scrollbar-thumb {
//   -webkit-border-radius: 1px;
//   border-radius: 1px;
//   background: var(--brand-color); 
// }


//checkmark color change to brand color
/* On mouse-over, add a grey background color */

.filter-label:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

.filter-label input:checked ~ .checkmark {
  border: 1px solid var(--brand-color);
}

.filter-label input:checked ~ .filter-label-text {
  color: var(--black);
}

.checkmark {
  position: relative;
  // margin-left: auto;
  margin-right: 4px;
  height: 20px;
  width: 20px;
  background-color: var(--white);
  border: 1px solid var(--grey);
  border-radius: 2px;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-label .checkmark.save-later-checkmark:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border-width: 0 2px 2px 0;
}
/* Show the checkmark when checked */

.filter-label input:checked ~ .checkmark:after {
  display: block;
}

.filter-label {
  margin: 0;
  display: flex;
}

/* Style the checkmark/indicator */

.filter-label .checkmark:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid var(--brand-color);
  border-width: 0 2px 2px 0;
}

.filter-label input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.save-later-checkn {
  @media (max-width: 991px) {
    padding: 0px;
  }
}

.savetraveller-text {
  font-weight: 500;
  font-size: 13px;
  color: var(--grey);
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}


points-xchange-details {
  .details-section {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

points-xchange-journey points-xchange-transfer-detail .transfer-detail {
  height: unset !important;
}

pv-divider {
  border-color: #bfbfbf;
}

points-xchange-journey points-xchange-pay-with-card {
  display: none;
}
.p-overlaypanel {
  margin: 0;
  .p-overlaypanel-content {
    padding: 10px;
    border: 1px solid #bfbfbf;
    
  }
}
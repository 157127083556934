.ngx-pagination .current{
    background: var(--brand-color) !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--yellow2)!important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple, .mat-radio-button.mat-accent .mat-radio-inner-circle{
    background-color: var(--yellow2)!important;
}

.ios-web-view{
    @media(max-width: 992px) {
        .flights-bottom-button-bar{
            padding-bottom: 70px !important;
        }
        .natgeo-eng-btn{
            padding-bottom: 70px !important;
        }
        .hotel-price-bar{
            padding-bottom: 70px !important;
        }
    }

}